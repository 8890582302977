.passer-button {
    text-decoration: none !important;
    box-shadow: none !important;
    border: none !important;
    text-transform: capitalize !important;
    font-family: 'Segoe UI' !important;
    font-size: .9rem !important;
    cursor: pointer;
}

.passer-btn-return {
  border-radius: 5px;
  text-decoration: none !important;
  box-shadow: none !important;
  border: none !important;
  background-color: var(--psr-background-return);
  color: var(--color-dark);
  text-transform: uppercase !important;
  font-family: 'Segoe UI' !important;
  font-size: 11px !important;
  cursor: pointer;
}

.passer-button.green-gradient {
    background-image: linear-gradient(#74E4A2, #149470);
}

.passer-blue-button {
    background-image: linear-gradient(#656fe1, #363b79);
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: .9rem !important;
    font-weight: bold;
    margin-left: 1rem !important;
    padding: .7rem 1rem;
}

.passer-white-button{
    background-color: #ffffff;
    border-radius: 5px;
    border: none;
    color: #404051;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    padding: 15px 49px;
}

.passer-purple-admin{
  background: transparent linear-gradient(180deg, #91CFF1 0%, #6BBAE9 100%) 0% 0% no-repeat padding-box !important;
  border: 1px solid #609ED6 !important;
  color: white !important;
  border-radius: 5px;
}

.passer-white-admin{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #555555 !important;
  box-shadow: 0px 4px 3px #CACAD929;
  border: 1px solid #DBDBEA !important;
  border-radius: 5px;
}

.passer-gray-liner{
  background-image: linear-gradient(#CACAD9, #DBDBEA) !important;
  color: #404051;
  border-radius: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 16px !important;
  padding: 15px 49px !important;
}

.dot{
  padding: 5px 15px;
  border: 1px solid #CACAD9 !important;
  &:first-child{
    margin-right: 10px;
  }
}



.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--ion-color-purple) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--ion-color-purple) !important;
}

.min-width-16{
  min-width: 16px !important
}

.lh-0{
  line-height: unset !important;
}
