.theme-dark{


    $loading-ios-background : #2c2c2c !important;
    $loading-md-background	: #2c2c2c !important;
    ion-content{
        background-color: #121212;
        color: white;
    }

    .header .toolbar-background{
        background-color: #272727 !important;
        border: #121212;
    }

    .toolbar-content-ios{
        background-color: transparent !important;
    }

    .toolbar-title{
        color: #fff;
    }

    ion-segment{
        background-color: #272727!important;
        border: #121212;
    }

    .titleFor{
        background-color: #272727!important;
        border: #121212;
    }

    ion-item{
        background-color: #272727!important;
        border: #121212;
        color: #fff !important;
    }

    .label-md, .label-ios{
        color: #fff !important;
    }

    .list-header{
        background-color: #272727!important;
        border: #121212;
        color: #fff !important;
    }

    .bar-button-default-md, .bar-button-default-ios{
        color: #fff !important;
        span, ion-icon{
            color: #fff !important;
        }
    }

    .rounded-div{
        background-color: #272727!important;
        border: #121212;
        
        i{
            color: #fff !important;
        }
    }

    .footer{
        background-color: #272727!important;
        border: #121212;
        
        i,p{
            color: #fff !important;
        }
    }

    .passer-card{
        background: #121212 !important;
        color: white !important;
    }

    ion-item.inputTarjeta.numberT, ion-item.inputTarjeta.numberTSaldo{
        background-color: transparent !important;
    }

    .toggle-div{
        ion-item{
            background-color: transparent !important;
        }
    }

    .myTextDiv{
        background-color: #272727!important;
        
    }

    .titleForItem{
        color: white !important;
    }

    .radio-checked {
        border-color: white !important;
    }

    .radio-inner{
        border-color: white !important;
    }

    .flags{
        img{
            background-color: transparent !important;
        }
    }

    .container-conditions-child{
        h2,h3,h4,h5,p,li{
            color: white !important;
        }
    }

    page-configuration{
        .list-md{
            margin: 16px 0px 16px;
        }

        ion-item{
            background-color: transparent !important;
        }

        .itemPerson{
            .label{
                p{
                    color: #9074c8 !important;
                }
            }
        }
    }

    .calendar-chooser{
        background-color: #272727!important;
        margin-top: 12px;
        i,p{
            color: white !important;
        }
    }

    .data-layer-frm{
        background-color: transparent !important;
        color: white !important;

    }

    .searchbar-ios .searchbar-input, .searchbar-ios .searchbar-input::placeholder,
    .searchbar-input, .searchbar-input::placeholder{
        background-color: #272727!important;
        color: #B9B9C8 !important;
    }

    #fav_favorites_tab{
        background-color: #272727!important;
        ion-col{
            background-color: #272727!important;
        }

        .textoRosadoFuerte{
            padding-top: 12px;
        }
        .currentTab{
             color: #6772E5 !important; 
        }
    }

    .currentTab2{
        background-color: #272727!important;
        color: white !important;
        font-weight: bold;
    }

    .SecondTab2{
        background-color: #272727!important;
        color: #404051 !important
    }

    page-configuration-security-options{
        p.blackText{
            color: white !important;
        }
        ion-list{
            ion-item{
                background-color: #121212 !important;
            }
        }
    }

    .ripple{
        i,.label,span, ion-icon{
            color: white !important; 
        }
    }

    page-tickets-suggetions{
        .containerInputs{
            margin-top: 10px;
        }
        ion-footer{
            width: 100%;
            margin: 0px;
        }
    } 

    .divB, .divA{
        background-color: #121212 !important;
        p{ color: white !important;}
    }

    .combo-box-content{
        background-color: #272727!important;
        color: white !important; 
    }

    .mysearch{
        ion-icon,p{
            color: white !important; 
        }
        background-color: #272727!important;
        color: white !important;
    }

    .salud, .question{
        color: white !important;
    }

    .presentingDiv{
        h4{
            color: white !important;
        }
    }

    .mialert{
        background-color: #272727!important;
        .chipModal{
            background-color: #272727!important;
            .chipModalTittle{
                background-color: #272727!important; 
            }
            #modalTitle{
                background-color: #272727!important;
                color: white !important;
            }
        }
    }

    page-fill-user-data{

        .header{
            .toolbar-background{
                background-color: #121212 !important;
            }
        }
        .label, ion-input{
            padding-left: 4px;
        }
    }

    page-forgot-pass{
        .header{
            .toolbar-background{
                background-color: #121212 !important;
            }
        }

        .id2{
            .label{
                color: white !important;
            }
        }

        ion-item{
            background-color: transparent !important;
        }
    }

    #container-fgt{
        background-color: #121212 !important;
        ion-item{
            background-color: transparent !important;
        }
        p{
            color: white !important;
        }

        h3{
            color: white !important;
            font-weight: bolder;
        }
    }

    page-login, page-user-signup{
        .header{
            .toolbar-background{
                background-color: transparent !important;
            }
        }

        .containerButton{
            ion-item{
                background-color: transparent !important;
            }
        }
    }

    page-modal-security{

        .header{
            .toolbar-background{
                background-color: #121212 !important;
            }

            & + div{
                background-color: #121212 !important;
            }
        }

        ion-item{
            background-color: transparent !important;
        }
    }

    page-tickets-home{

        #fav_favorites_tab{
            .col{
                display: flex;
                align-items: center;
                justify-content: center;
                .textoRosadoFuerte{
                    padding-top: 0px !important;
                }
            }
            .col.currentTab{
                
                background-color: #121212 !important;
                .textoRosadoFuerte{
                    color: #9074c8 !important;
                }
            }
        }
    }

    .btn-purple{
        background-color: #9074c8;
        span{
            color: rgba(0,0,0,0.87);
        }
    }

    .rounded-div{
        box-shadow: none;
    }

    .rounded-button{
        background-color: #9074c8 !important;
        color: #FFF;
    }

    .form{
        background-color: #121212!important;
        ion-item{
            background-color: transparent !important;
        }
    }

    .footerContainer{
        button{
            background-color: #9074c8 !important;
            span{
                color: white !important
            }
        }
    }

    .toggle-icon{
        .toggle-inner{
            background-color: #9074c8 !important;
        }
    }

    page-notifications{
        .divA{
            background-color: #121212 !important;
            ion-item{
                background-color: #121212 !important;
            }
            .element{
                background-color: rgba($color: #121212, $alpha: 0.40) ;
            }
        }
    }

    .divA{
        ion-item{
            background-color: #121212 !important;
            border-bottom: 0.55px solid #121212 !important;
        }
        .element{
            background-color: rgba($color: #121212, $alpha: 0.40) ;
        }
    }

    page-configuration-tickets-history{
        .list{
            ion-item{
                background-color: #121212 !important;
                .label{
                    h4{
                        color: white;
                    }
                    p{
                        color: rgba($color: white, $alpha: 0.60) ;
                    }

                    .text-date{
                        color: #8f73c7;
                    }
                }
            }
        }

        .bts{
            background-color: #121212 !important;
            ion-icon{
                color: white;
            }
        }

        .bus-details{
            background-color: rgba($color: #121212, $alpha: 0.16) ;
            p{
                color: white;
            }

            &__section-two{
                button{
                    background-color: #9074c8 !important;
                }
            }
        }

        #fav_favorites_tab{ 
            background-color: transparent !important;
            ion-col{ background-color: transparent !important; }
        }
    }

    page-bought-ticket-modal{

        h2{
            color: white;
        }

        ion-item{
            background-color: transparent !important;
        }

        .rowBarcodeBot, .rowBarcode2, .rowBarcode{
            background-color: #121212!important;
            .topCard{
                color: rgba($color: white, $alpha: 0.60) ;
            }
        }
        .rowBarcode2{
            border-radius: 0px;
        }
        .fab{
            background-color: #9074c8 !important;
        }

        .modal-container{
            background-color: #121212!important;
        }
    }

    page-map-options{
        .pIos{
            background-color: #272727 !important;
            border: #121212;
            & + div{
                i{
                    color: rgba($color: white, $alpha: 0.60) ;
                }
                .purpleText{
                    color: rgba($color: white, $alpha: 0.87) !important;
                    & + span{
                        color: rgba($color: white, $alpha: 0.87) ;
                    }
                }

                span{
                    color: rgba($color: white, $alpha: 0.60) ;
                }
            } 
        }
    }

 
        .element {
            animation: pulse-black 2s infinite;
        }
        
        @keyframes pulse-black {
            0% {
                background-color: #383838;
            }
            50% {
                background-color: #272727;
            }
            100% {
                background-color: #383838;
            }
        }
    
        .list-ios .item-block .item-inner , .list-md .item-block .item-inner  {
            border-bottom: 0px solid #121212;
        }
    
        
        .picker-wrapper{
            background-color: #383838 !important;

            .picker-toolbar{
                background-color: #383838 !important; 

            }

            .picker-toolbar-button{
                background-color: #383838 !important;
                button{
                    span{
                        color: #9074c8;
                    }
                }
                
            }

            .picker-toolbar-cancel{
                button{
                    span{
                        color: rgba($color: white, $alpha: 0.60) !important;
                    }
                }
            }

            .picker-opt{
                color: rgba($color: white, $alpha: 0.60) !important;
            }
            button.picker-opt.picker-opt-selected{
                color: rgba($color: white, $alpha: 1) !important;
            }
        }
        

        page-passer-card{
            .list{
                .list-header{
                    background-color: transparent !important;  
                }
                ion-item{
                    background-color: transparent !important;
                    ion-avatar{
                        i{
                            color: rgba($color: white, $alpha: 1) !important; 
                        }
                    }
                    p{
                        color: rgba($color: white, $alpha: 0.87) !important;
                    }
                }
            }

            .methodClass{
                color: rgba($color: white, $alpha: 1) !important; 
            }

            .bts {
                background-color: #121212 !important;
                color: rgba($color: white, $alpha: 1) !important;
                span{
                    ion-icon{
                        color: rgba($color: white, $alpha: 1) !important;
                    }
                }
            }

            ion-footer{
                .tab-footer{ 
                    background-color: #272727 !important;
                }
            }
        }

        tarjeta{
            .ripple{
                background-color: #272727!important; 
            }

            form{
                ion-item{
                    background-color: #121212 !important;
                }
            }

            .containerTextMessage{
                color: white !important;
            }
        }

        page-modify-card{

            .ripple{
                background-color: #272727!important; 
            }

            ion-item{
                background-color: #121212 !important;
            }
        }


        .errorTitle{
            color: #cf6679 !important;
        }

        .normalTitle{
            color: #8f73c7 !important;
        }

        languages, themes{
            ion-item{
                background-color: transparent !important;
            }
        }

        page-fill-creditcard-data{
            .containerTextMessage{
                color: white !important;
            }
        }

        .loading-wrapper{
            background-color: #383838 !important;
           
            .spinner.spinner-crescent circle {
                stroke: #9074c8 !important;
            }

            .loading-content{
                color: white !important;
                font-weight: 600;
            }
        }

        .alert-wrapper{
            background-color: transparent !important;
            box-shadow: none !important;
            .alert-head{
                background-color: #2e2e2e !important; 
            }

            .alert-radio-group{
                background-color: #1e1e1e !important;
            }
            
            .alert-title{
                color: white !important;
            }

            .alert-button{
                background-color: #2e2e2e !important; 
                border: 0px solid #383838;
                &:last-of-type{
                    border-left: 0.55px solid #383838 !important;
                    span{
                        color: #8f73c7 !important;
                    }
                }
                &:first-of-type{
                    span{
                        color: rgba($color: white, $alpha: 0.87) !important;
                    }
                }
                
            }

            .alert-radio-label{
                color: white !important;
            }

            [aria-checked=true] .alert-radio-label,   [aria-checked=true] .alert-radio-label{
                color: #8f73c7 !important;
            }

           [aria-checked=true] .alert-radio-icon,   [aria-checked=true] .alert-radio-icon{
                border-color: #8f73c7 !important;
            }

          [aria-checked=true] .alert-radio-inner,  [aria-checked=true] .alert-radio-inner {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
                background-color: #8f73c7 !important;
            }
        }
        .radio-item{
            background-color: transparent !important;
            .radio-checked{
                background-color: white !important;
                border-color: #8f73c7 !important;
                .radio-inner{
                    background-color: #8f73c7 !important;
                }
            }
        }

        .left{
            .primary{
                color: #8f73c7 !important;
            }
        }


        page-settings{
            .bus-container--left-side{
                background-color: #2d2d2d !important; 
            }

            .bus-container--right-side{
                background-color: #2d2d2d !important; 
            }
            .bus-container--center-side{
                background-color: #121212;
            }

            .bus-container--back-side{
                background-color: #353535 !important; 
            }

            .normal{
                background-color: #cdc6df !important; 
                .text_number{
                    color: #121212 !important;
                }
            }

            .toggle__button{
                background-color: #373737 !important; 
                p{
                    color: #8f73c7 !important;
                }
            }

            .selected {
                background-color: #8f73c7 !important;
                color: #121212 !important;
            }
        }


        .bordT{
            .left{
                color: white;
                b{
                    color: #8f73c7 !important;
                }
            }
        }
        desc{
            p{
                color: white !important;
            }
        }

        .paymentAmountDiv{
            background-color: #272727 !important;
        }
        
    #FirstChildtAXIS{
        background-color: #272727 !important;
        color: white !important;
    }
    #containerOFDataTaxis{
        background-color: #121212 !important;
        color: white !important;
    }
}

 .ios .theme-dark{
    .toolbar-content-ios{
        background-color: transparent !important;
    }

    page-configuration{
        .toolbar-content-ios{
            background-color: transparent !important;
        }
    }

    page-tickets-home{
        div.toolbar-content.toolbar-content-ios{ background-color: transparent !important; }
    }

    .alert-wrapper{
        [aria-checked=true] .alert-radio-inner,  [aria-checked=true] .alert-radio-inner {
            -webkit-transform: scale3d();
            transform: scale3d();
            border-color: #8f73c7 !important;
        }
    }

    .alert-message{ background-color:#2e2e2e !important; }

    .segment-ios-secondary .segment-button.segment-activated { background-color: #272727 !important; }
 }