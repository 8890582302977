.section {
    position: relative;
    padding-left: 10%;
    padding-right: 10%;
    overflow: hidden;
    float: left;
    width: 100%;
}

.section.full {
    position: relative;
    padding-left: 0%;
    padding-right: 0%;
    overflow: hidden;
}

.block.left {
    width: 60%;
    float: left;
}

.block.right {
    width: 40%;
    float: left;
}

.padding-left {
    padding-left: 16px !important;
}

.padding-right {
    padding-right: 16px !important;
}

.padding-bottom {
    padding-bottom: 16px !important;
}

.no-mar-b{
  margin-bottom: 0px!important;
}

.text-center{ text-align: center !important; }
.text-left{ text-align: left !important; }
.text-right{ text-align: right !important; }
.text-justify{ text-align: justify !important; }

.uppercase{ text-transform: uppercase !important; }
.lowercase{ text-transform: lowercase !important; }
.capitalize{ text-transform: capitalize !important; }

.inline-block { display: inline-block !important; }
.block{ display: block !important; }
.flex{ display: flex !important; }

.align-items-center{ align-items: center !important; }

.psr-divider{
  border: 0.5px solid #DBDBEA;
  margin: 16px 0;
}

.none{ display: none !important; }
.psr-hide{ visibility: hidden !important; }

.w-100{ width: 100% !important; }
