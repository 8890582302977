.b-none{
    border: none !important;
}

.br-4{
    border-radius: 4px !important;
}

.br-8{
    border-radius: 8px !important;
}

.br-half{
    border-radius: 50% !important;
}

.br-0{
    border-radius: 0% !important;
    --border-radius: 0% !important;
}

.border-top-gray {
    border-top: 1px solid #DBDBEA !important;
}

.border-bottom-gray {
    border-bottom: 1px solid #DBDBEA !important;
}

.border-left-gray {
    border-left: 1px solid #DBDBEA !important;
}

.border-right-gray {
    border-right: 1px solid #DBDBEA !important;
}

.full-border-gray{
    border: #d4d4d4 solid 1px !important;
}

.full-border-gray-b{
    border-bottom: 1px solid var(--ion-color-passer-border-gray-1) !important;
}

.full-border-gray-focus{
    border: 1px solid var(--ion-color-boxFocus) !important;
}

.border-purple{
    border: 1px solid var(--ion-color-purple) !important;
}

.right-border-gray-1{
    border-right: 1px solid #d4d4d4 !important;
}

.box-s-b{
    box-shadow: 1px 1px 6px 0px var(--color-box-s1);
}

.box-s-b-2{
    box-shadow: 0px 1px 7px 4px var(--ion-color-passer-border-gray-1);
}

.box-s-b-3{
    box-shadow: 1px 1px 4px 0px var(--ion-color-passer-border-gray-1);
}

.bor-gra-b{
    border-bottom: 1px solid var(--ion-color-passer-border-gray-1) !important;
}
