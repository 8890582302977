
$font-path: '../assets/fonts';

$section-margin: 80px;
$vertical-margin: 16px;
$green: #149470;
$purple: #604896;
$purpleDark: #604897;
$yellow: #FBDB6B;
$error: #F44336;
$purpleLight: #6772E5;
$text: #404051;
$blue: #4088F7;
$border: #DBDBEA;

:root {
    // colores predefinidos
    --ion-color-purple: #604896;
    --ion-color-gray-cancel: #DDDDDD;
    --ion-color-arrowdown: #CACAD9;
    --ion-color-boxFocus: #CACAD9;
    --ion-color-cbxText: #B9B9C8;
    --ion-color-borderFocus: #0292D8;
    --ion-color-light-blue: 53,172,230;
    --ion-color-gray-2: #ECECFB;
    --ion-color-gray-3-x: #dbdbea;
    --ion-color-blue-title: #35ACE6;


    --color-dark-gray: #B9B9C8;
    --color-dark: #404051;
    --color-text-label: rgba(0, 0, 0, 0.6);
    --color-cyan: #609ED6;
    --color-box-s1: #d1d1d1;


    --psr-background-return: #6200EE0A;
    --psr-border: #DBDBEA;
    --psr-purple: #604897;
}




.bg-cyan{
    background-color: var(--color-cyan) !important;
}

.bg-purple{
    background-color: var(--ion-color-purple) !important;
}

.bg-gray-cancel{
  background-color: var(--ion-color-gray-cancel) !important;
}

.bg-dark{
    background-color: var(--ion-color-purple) !important;
}

.bg-dark-gray{
    background-color: var(--color-dark-gray) !important;
}

.bg-gray-2{
    background-color: var(--ion-color-gray-3-x) !important;
}



.color-white{
    color: #fff !important;
}

.color-purple{
  color: var(--ion-color-purple) !important;
}

.color-blue-title{
  color: var(--ion-color-blue-title) !important;
}
