.passer-select {
    background-image: linear-gradient(45deg, transparent 50%, #DBDBEA 50%), linear-gradient(135deg, #DBDBEA 50%, transparent 50%) !important;
    background-position: calc(100% - 20px) center, calc(100% - 15px) center, calc(100% - 3em) center !important;
    background-size: 5px 5px, 5px 5px, 1px 1.5em !important;

    background-image: url("/assets/img/arrow-down.png") !important;
    background-size: 10px !important;
    margin-bottom: 0px !important;
    border: solid 1px #DBDBEA;
    border-radius: 5px !important;
    background-repeat: no-repeat !important;
    box-shadow: none !important;
    display: inline !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    font-family: "Segoe UI" !important;
    color: gray !important;
    font-size: .9rem !important;
    font-family: sans-serif !important;
    padding: 8px 16px !important;
    option {
        background: white !important;
        font-family: "Segoe UI" !important;
        color: gray !important;
        font-size: .9rem !important;
        border: none !important;
    }
}

// Tablet
@media (min-width: 1600px) {
    .passer-select {
        font-size: 1.25rem;
        option {
            font-size: 1.25rem;
        }
    }
}


  .passer-select.ng-dirty.ng-valid.ng-touched{
    margin: 0px !important;
  }
  .passer-select.ng-untouched.ng-pristine.ng-invalid {
    margin: 0px;
  }

  .passer-select.input-field{
    height: 45px;
    margin: 0px !important;
  }

.mat-select-value{

  .mat-select-value-text{
    line-height: 36px;
  }

}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper{
  transform: translateY(-2px) !important;
}

.mat-select-trigger{
  height: 36px !important;
}


.mat-select-search-input {
  padding-left: 16px !important;
  margin-bottom: 0 !important;
  height: 40px !important;
}