$spacer: 1rem !default;
$spacers: (
    0: 0,
    2: $spacer * 0.125,
    4: $spacer * 0.25,
    8: $spacer * 0.5,
    10: $spacer * 0.625,
    12: $spacer * 0.75,
    16: $spacer,
    20: $spacer * 1.25,
    24: $spacer * 1.5,
    32: $spacer * 2,
    40: $spacer * 2.5,
    48: $spacer * 3,
    56: $spacer * 3.5,
    64: $spacer * 4,
);

.m-0 { margin:0!important; }
.m-2 { margin:map-get($spacers,2)!important; }
.m-4 { margin:map-get($spacers,4)!important; }
.m-12 { margin:map-get($spacers,12)!important; }
.m-16 { margin:map-get($spacers,16)!important; }
.m-20 { margin:map-get($spacers,20)!important; }
.m-24 { margin:map-get($spacers,24)!important; }
.m-32 { margin:map-get($spacers,32)!important; }
.m-40 { margin:map-get($spacers,40)!important; }
.m-48 { margin:map-get($spacers,48)!important; }
.m-56 { margin:map-get($spacers,56)!important; }
.m-64 { margin:map-get($spacers,64)!important; }

.mt-0 { margin-top:0!important; }
.mr-0 { margin-right:0!important; }
.mb-0 { margin-bottom:0!important; }
.ml-0 { margin-left:0!important; }
.mx-0 { margin-left:0!important;margin-right:0!important; }
.my-0 { margin-top:0!important;margin-bottom:0!important; }

.mt-2 { margin-top:map-get($spacers,2)!important; }
.mr-2 { margin-right:map-get($spacers,2)!important; }
.mb-2 { margin-bottom:map-get($spacers,2)!important; }
.ml-2 { margin-left:map-get($spacers,2)!important; }
.mx-2 { margin-right:map-get($spacers,2)!important;margin-left:map-get($spacers,2)!important; }
.my-2 { margin-top:map-get($spacers,2)!important;margin-bottom:map-get($spacers,2)!important; }

.mt-4 { margin-top:map-get($spacers,4)!important; }
.mr-4 { margin-right:map-get($spacers,4)!important; }
.mb-4 { margin-bottom:map-get($spacers,4)!important; }
.ml-4 { margin-left:map-get($spacers,4)!important; }
.mx-4 { margin-right:map-get($spacers,4)!important;margin-left:map-get($spacers,4)!important; }
.my-4 { margin-top:map-get($spacers,4)!important;margin-bottom:map-get($spacers,4)!important; }

.mt-8 { margin-top:map-get($spacers,8)!important; }
.mr-8 { margin-right:map-get($spacers,8)!important; }
.mb-8 { margin-bottom:map-get($spacers,8)!important; }
.ml-8 { margin-left:map-get($spacers,8)!important; }
.mx-8 { margin-right:map-get($spacers,8)!important;margin-left:map-get($spacers,8)!important; }
.my-8 { margin-top:map-get($spacers,8)!important;margin-bottom:map-get($spacers,8)!important; }

.mt-12 { margin-top:map-get($spacers,12)!important; }
.mr-12 { margin-right:map-get($spacers,12)!important; }
.mb-12 { margin-bottom:map-get($spacers,12)!important; }
.ml-12 { margin-left:map-get($spacers,12)!important; }
.mx-12 { margin-right:map-get($spacers,12)!important;margin-left:map-get($spacers,12)!important; }
.my-12 { margin-top:map-get($spacers,12)!important;margin-bottom:map-get($spacers,12)!important; }

.mt-16 { margin-top:map-get($spacers,16)!important; }
.mr-16 { margin-right:map-get($spacers,16)!important; }
.mb-16 { margin-bottom:map-get($spacers,16)!important; }
.ml-16 { margin-left:map-get($spacers,16)!important; }
.mx-16 { margin-right:map-get($spacers,16)!important;margin-left:map-get($spacers,16)!important; }
.my-16 { margin-top:map-get($spacers,16)!important;margin-bottom:map-get($spacers,16)!important; }

.mt-20 { margin-top:map-get($spacers,20)!important; }
.mr-20 { margin-right:map-get($spacers,20)!important; }
.mb-20 { margin-bottom:map-get($spacers,20)!important; }
.ml-20 { margin-left:map-get($spacers,20)!important; }
.mx-20 { margin-right:map-get($spacers,20)!important;margin-left:map-get($spacers,20)!important; }
.my-20 { margin-top:map-get($spacers,20)!important;margin-bottom:map-get($spacers,20)!important; }

.mt-24 { margin-top:map-get($spacers,24)!important; }
.mr-24 { margin-right:map-get($spacers,24)!important; }
.mb-24 { margin-bottom:map-get($spacers,24)!important; }
.ml-24 { margin-left:map-get($spacers,24)!important; }
.mx-24 { margin-right:map-get($spacers,24)!important;margin-left:map-get($spacers,24)!important; }
.my-24 { margin-top:map-get($spacers,24)!important;margin-bottom:map-get($spacers,24)!important; }

.mt-32 { margin-top:map-get($spacers,32)!important; }
.mr-32 { margin-right:map-get($spacers,32)!important; }
.mb-32 { margin-bottom:map-get($spacers,32)!important; }
.ml-32 { margin-left:map-get($spacers,32)!important; }
.mx-32 { margin-right:map-get($spacers,32)!important;margin-left:map-get($spacers,32)!important; }
.my-32 { margin-top:map-get($spacers,32)!important;margin-bottom:map-get($spacers,32)!important; }

.mt-40 { margin-top:map-get($spacers,40)!important; }
.mr-40 { margin-right:map-get($spacers,40)!important; }
.mb-40 { margin-bottom:map-get($spacers,40)!important; }
.ml-40 { margin-left:map-get($spacers,40)!important; }
.mx-40 { margin-right:map-get($spacers,40)!important;margin-left:map-get($spacers,40)!important; }
.my-40 { margin-top:map-get($spacers,40)!important;margin-bottom:map-get($spacers,40)!important; }

.mt-48 { margin-top:map-get($spacers,48)!important; }
.mr-48 { margin-right:map-get($spacers,48)!important; }
.mb-48 { margin-bottom:map-get($spacers,48)!important; }
.ml-48 { margin-left:map-get($spacers,48)!important; }
.mx-48 { margin-right:map-get($spacers,48)!important;margin-left:map-get($spacers,48)!important; }
.my-48 { margin-top:map-get($spacers,48)!important;margin-bottom:map-get($spacers,48)!important; }

.mt-56 { margin-top:map-get($spacers,56)!important; }
.mr-56 { margin-right:map-get($spacers,56)!important; }
.mb-56 { margin-bottom:map-get($spacers,56)!important; }
.ml-56 { margin-left:map-get($spacers,56)!important; }
.mx-56 { margin-right:map-get($spacers,56)!important;margin-left:map-get($spacers,56)!important; }
.my-56 { margin-top:map-get($spacers,56)!important;margin-bottom:map-get($spacers,56)!important; }

.mt-64 { margin-top:map-get($spacers,64)!important; }
.mr-64 { margin-right:map-get($spacers,64)!important; }
.mb-64 { margin-bottom:map-get($spacers,64)!important; }
.ml-64 { margin-left:map-get($spacers,64)!important; }
.mx-64 { margin-right:map-get($spacers,64)!important;margin-left:map-get($spacers,64)!important; }
.my-64 { margin-top:map-get($spacers,64)!important;margin-bottom:map-get($spacers,64)!important; }

.mt-auto { margin-top:auto!important; }
.mr-auto { margin-right:auto!important; }
.mb-auto { margin-bottom:auto!important; }
.ml-auto { margin-left:auto!important; }
.mx-auto { margin-right:auto!important;margin-left:auto!important; }
.my-auto { margin-bottom:auto!important;margin-top:auto!important; }


//PADDING
.pt-0 { padding-top:0!important; }
.pr-0 { padding-right:0!important; }
.pb-0 { padding-bottom:0!important; }
.pl-0 { padding-left:0!important; }
.px-0 { padding-left:0!important;padding-right:0!important; }
.py-0 { padding-top:0!important;padding-bottom:0!important; }

.pt-2 { padding-top:map-get($spacers,2)!important; }
.pr-2 { padding-right:map-get($spacers,2)!important; }
.pb-2 { padding-bottom:map-get($spacers,2)!important; }
.pl-2 { padding-left:map-get($spacers,2)!important; }
.px-2 { padding-right:map-get($spacers,2)!important;padding-left:map-get($spacers,2)!important; }
.py-2 { padding-top:map-get($spacers,2)!important;padding-bottom:map-get($spacers,2)!important; }

.pt-4 { padding-top:map-get($spacers,4)!important; }
.pr-4 { padding-right:map-get($spacers,4)!important; }
.pb-4 { padding-bottom:map-get($spacers,4)!important; }
.pl-4 { padding-left:map-get($spacers,4)!important; }
.px-4 { padding-right:map-get($spacers,4)!important;padding-left:map-get($spacers,4)!important; }
.py-4 { padding-top:map-get($spacers,4)!important;padding-bottom:map-get($spacers,4)!important; }

.pt-8 { padding-top:map-get($spacers,8)!important; }
.pr-8 { padding-right:map-get($spacers,8)!important; }
.pb-8 { padding-bottom:map-get($spacers,8)!important; }
.pl-8 { padding-left:map-get($spacers,8)!important; }
.px-8 { padding-right:map-get($spacers,8)!important;padding-left:map-get($spacers,8)!important; }
.py-8 { padding-top:map-get($spacers,8)!important;padding-bottom:map-get($spacers,8)!important; }

.pt-12 { padding-top:map-get($spacers,12)!important; }
.pr-12 { padding-right:map-get($spacers,12)!important; }
.pb-12 { padding-bottom:map-get($spacers,12)!important; }
.pl-12 { padding-left:map-get($spacers,12)!important; }
.px-12 { padding-right:map-get($spacers,12)!important;padding-left:map-get($spacers,12)!important; }
.py-12 { padding-top:map-get($spacers,12)!important;padding-bottom:map-get($spacers,12)!important; }

.pt-16 { padding-top:map-get($spacers,16)!important; }
.pr-16 { padding-right:map-get($spacers,16)!important; }
.pb-16 { padding-bottom:map-get($spacers,16)!important; }
.pl-16 { padding-left:map-get($spacers,16)!important; }
.px-16 { padding-right:map-get($spacers,16)!important;padding-left:map-get($spacers,16)!important; }
.py-16 { padding-top:map-get($spacers,16)!important;padding-bottom:map-get($spacers,16)!important; }

.pt-20 { padding-top:map-get($spacers,20)!important; }
.pr-20 { padding-right:map-get($spacers,20)!important; }
.pb-20 { padding-bottom:map-get($spacers,20)!important; }
.pl-20 { padding-left:map-get($spacers,20)!important; }
.px-20 { padding-right:map-get($spacers,20)!important;padding-left:map-get($spacers,20)!important; }
.py-20 { padding-top:map-get($spacers,20)!important;padding-bottom:map-get($spacers,20)!important; }

.pt-24 { padding-top:map-get($spacers,24)!important; }
.pr-24 { padding-right:map-get($spacers,24)!important; }
.pb-24 { padding-bottom:map-get($spacers,24)!important; }
.pl-24 { padding-left:map-get($spacers,24)!important; }
.px-24 { padding-right:map-get($spacers,24)!important;padding-left:map-get($spacers,24)!important; }
.py-24 { padding-top:map-get($spacers,24)!important;padding-bottom:map-get($spacers,24)!important; }

.pt-32 { padding-top:map-get($spacers,32)!important; }
.pr-32 { padding-right:map-get($spacers,32)!important; }
.pb-32 { padding-bottom:map-get($spacers,32)!important; }
.pl-32 { padding-left:map-get($spacers,32)!important; }
.px-32 { padding-right:map-get($spacers,32)!important;padding-left:map-get($spacers,32)!important; }
.py-32 { padding-top:map-get($spacers,32)!important;padding-bottom:map-get($spacers,32)!important; }

.pt-40 { padding-top:map-get($spacers,40)!important; }
.pr-40 { padding-right:map-get($spacers,40)!important; }
.pb-40 { padding-bottom:map-get($spacers,40)!important; }
.pl-40 { padding-left:map-get($spacers,40)!important; }
.px-40 { padding-right:map-get($spacers,40)!important;padding-left:map-get($spacers,40)!important; }
.py-40 { padding-top:map-get($spacers,40)!important;padding-bottom:map-get($spacers,40)!important; }

.pt-48 { padding-top:map-get($spacers,48)!important; }
.pr-48 { padding-right:map-get($spacers,48)!important; }
.pb-48 { padding-bottom:map-get($spacers,48)!important; }
.pl-48 { padding-left:map-get($spacers,48)!important; }
.px-48 { padding-right:map-get($spacers,48)!important;padding-left:map-get($spacers,48)!important; }
.py-48 { padding-top:map-get($spacers,48)!important;padding-bottom:map-get($spacers,48)!important; }

.pt-56 { padding-top:map-get($spacers,56)!important; }
.pr-56 { padding-right:map-get($spacers,56)!important; }
.pb-56 { padding-bottom:map-get($spacers,56)!important; }
.pl-56 { padding-left:map-get($spacers,56)!important; }
.px-56 { padding-right:map-get($spacers,56)!important;padding-left:map-get($spacers,56)!important; }
.py-56 { padding-top:map-get($spacers,56)!important;padding-bottom:map-get($spacers,56)!important; }

.pt-64 { padding-top:map-get($spacers,64)!important; }
.pr-64 { padding-right:map-get($spacers,64)!important; }
.pb-64 { padding-bottom:map-get($spacers,64)!important; }
.pl-64 { padding-left:map-get($spacers,64)!important; }
.px-64 { padding-right:map-get($spacers,64)!important;padding-left:map-get($spacers,64)!important; }
.py-64 { padding-top:map-get($spacers,64)!important;padding-bottom:map-get($spacers,64)!important; }

.p-0 { padding:0!important; }
.p-2 { padding:map-get($spacers,2)!important; }
.p-4 { padding:map-get($spacers,4)!important; }
.p-8 { padding:map-get($spacers,8)!important; }
.p-12 { padding:map-get($spacers,12)!important; }
.p-16 { padding:map-get($spacers,16)!important; }
.p-20 { padding:map-get($spacers,20)!important; }
.p-24 { padding:map-get($spacers,24)!important; }
.p-32 { padding:map-get($spacers,32)!important; }
.p-40 { padding:map-get($spacers,40)!important; }
.p-48 { padding:map-get($spacers,48)!important; }
.p-56 { padding:map-get($spacers,56)!important; }
.p-64 { padding:map-get($spacers,64)!important; }


.p-start-0{
    --padding-start:0px !important;
}
