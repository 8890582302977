/* Global Styles */
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-family: "Segoe UI";
  box-sizing: border-box;
}

.container{
  width: 100%;
  margin: 0px;
  max-width: inherit;
}

@media only screen and (min-width: 993px){
  .container{
    padding-left: calc(10% + 16px);
    padding-right: calc(10% + 16px);
  }
}

@media only screen and (max-width: 1150px){
  .container{
    padding-left: calc(3% + 16px);
    padding-right: calc(3% + 16px);
  }
}

.noP{
  padding: 0px;
}

.psr-container-fluid {
  padding: 0 !important;
  margin: auto !important;
}

.psr-container-fluid,
.psr-container {
  width: 100%;
  max-width: 1280px;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;

  .psr-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    [class*="psr-col-"] {
      float: left;
      padding: 0.5em 0.5em;
      //border: 1px solid rgb(255, 0, 0);
      //background: rgba(112, 255, 112, 0.25);
    }

    /* ExtraSmall Screens (xs) */
    @media (max-width: 576px) {
      .psr-col-xs-12 {
        width: 100%;
      }
      .psr-col-xs-11 {
        width: 91.66666667%;
      }
      .psr-col-xs-10 {
        width: 83.33333333%;
      }
      .psr-col-xs-9 {
        width: 75%;
      }
      .psr-col-xs-8 {
        width: 66.66666667%;
      }
      .psr-col-xs-7 {
        width: 58.33333333%;
      }
      .psr-col-xs-6 {
        width: 50%;
      }
      .psr-col-xs-5 {
        width: 41.66666667%;
      }
      .psr-col-xs-4 {
        width: 33.33333333%;
      }
      .psr-col-xs-3 {
        width: 25%;
      }
      .psr-col-xs-2 {
        width: 16.66666667%;
      }
      .psr-col-xs-1 {
        width: 8.33333333%;
      }
    }

    /* Small Screens (sm) */
    @media (min-width: 576px) and (max-width: 767px) {
       .psr-col-sm-12 {
        width: 100%;
      }
      .psr-col-sm-11 {
        width: 91.66666667%;
      }
      .psr-col-sm-10 {
        width: 83.33333333%;
      }
      .psr-col-sm-9 {
        width: 75%;
      }
      .psr-col-sm-8 {
        width: 66.66666667%;
      }
      .psr-col-sm-7 {
        width: 58.33333333%;
      }
      .psr-col-sm-6 {
        width: 50%;
      }
      .psr-col-sm-5 {
        width: 41.66666667%;
      }
      .psr-col-sm-4 {
        width: 33.33333333%;
      }
      .psr-col-sm-3 {
        width: 25%;
      }
      .psr-col-sm-2 {
        width: 16.66666667%;
      }
      .psr-col-sm-1 {
        width: 8.33333333%;
      }
    }

    /* Medium Screens (md) */
    @media (min-width: 768px) and (max-width: 991px) {
      .psr-col-md-12 {
        width: 100%;
      }
      .psr-col-md-11 {
        width: 91.66666667%;
      }
      .psr-col-md-10 {
        width: 83.33333333%;
      }
      .psr-col-md-9 {
        width: 75%;
      }
      .psr-col-md-8 {
        width: 66.66666667%;
      }
      .psr-col-md-7 {
        width: 58.33333333%;
      }
      .psr-col-md-6 {
        width: 50%;
      }
      .psr-col-md-5 {
        width: 41.66666667%;
      }
      .psr-col-md-4 {
        width: 33.33333333%;
      }
      .psr-col-md-3 {
        width: 25%;
      }
      .psr-col-md-2 {
        width: 16.66666667%;
      }
      .psr-col-md-1 {
        width: 8.33333333%;
      }
    }

    /* Large Screens (lg) */
    @media (min-width: 992px) and (max-width: 1199px) {
      .psr-col-lg-12 {
        width: 100%;
      }
      .psr-col-lg-11 {
        width: 91.66666667%;
      }
      .psr-col-lg-10 {
        width: 83.33333333%;
      }
      .psr-col-lg-9 {
        width: 75%;
      }
      .psr-col-lg-8 {
        width: 66.66666667%;
      }
      .psr-col-lg-7 {
        width: 58.33333333%;
      }
      .psr-col-lg-6 {
        width: 50%;
      }
      .psr-col-lg-5 {
        width: 41.66666667%;
      }
      .psr-col-lg-4 {
        width: 33.33333333%;
      }
      .psr-col-lg-3 {
        width: 25%;
      }
      .psr-col-lg-2 {
        width: 16.66666667%;
      }
      .psr-col-lg-1 {
        width: 8.33333333%;
      }
    }

    /* ExtraLarge Screens (xl) */
    @media (min-width: 1200px) {
      .psr-col-xl-12 {
        width: 100%;
      }
      .psr-col-xl-11 {
        width: 91.66666667%;
      }
      .psr-col-xl-10 {
        width: 83.33333333%;
      }
      .psr-col-xl-9 {
        width: 75%;
      }
      .psr-col-xl-8 {
        width: 66.66666667%;
      }
      .psr-col-xl-7 {
        width: 58.33333333%;
      }
      .psr-col-xl-6 {
        width: 50%;
      }
      .psr-col-xl-5 {
        width: 41.66666667%;
      }
      .psr-col-xl-4 {
        width: 33.33333333%;
      }
      .psr-col-xl-3 {
        width: 25%;
      }
      .psr-col-xl-2 {
        width: 16.66666667%;
      }
      .psr-col-xl-1 {
        width: 8.33333333%;
      }
    }
  }

  .psr-align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .psr-align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .psr-align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .psr-align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .psr-justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .psr-justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .psr-justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .psr-align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .psr-align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .psr-align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
}

ngx-material-timepicker-content{
  .timepicker{
    header{ background-color: #6772E5 !important; }
    .timepicker__main-content{
      .timepicker__body{
        .clock-face__clock-hand,
        .clock-face__number > span.active{ background-color: #6772E5 !important; }
      }
      .timepicker__actions{ .timepicker-button{ color: #6772E5 !important; } }
    }
  }
}

.mat-datepicker-content{
  .mat-calendar-table{
    tr{
      border-bottom: none !important;
    }
  }
}

.color-1{ color : #9C64C0; }

.textPBE{
  ul{
    padding-left: 16px !important;
    li{ list-style-type: initial !important; }
  }
}

.ins{ text-decoration: underline !important; }

.faqDiv{
  a{
    color: #604897;
    display: inline-block;
    align-items: center;
    font-size: 0.8rem !important;
    text-decoration: none;
  }
}

.bubble{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 1;
  .sub-bubble{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 2px 3px #404051;
    text-align: center;
    cursor: pointer;
    background-color: white;
    img{
      width: 60%;
      margin-top: 13px;
    }
  }
  span{
    font-size: 1.2em;
    display: block;
    text-align: center;
  }
}

.topics {
  position: absolute;
  top: 30px;
  width: 20%;
  section{
    width: calc(100% - 24px); // !important;
    margin-left: 20px;
    margin-bottom: 15px;
    .search{
      height: 3rem !important;
    }
    select{
      width: 100% !important;
      margin-left: 0px;
      font-size: 0.9rem !important;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      position: relative;
      display: block;
      a, span {
        line-height: 1.54;
        display: block;
        padding: 13px 24px 2px;
        text-decoration: none;
        font-family: "Freight Sans Bold", Helvetica, Arial, sans-serif !important;
        font-size: 0.9375em !important;
        letter-spacing: normal;
        text-rendering: optimizelegibility;
        color: #404051;
      }
      .arrow-d{
        background-position: 95% 18px;
        background-image: url("/assets/img/arrow-down.png");
        background-size: 10px;
        background-repeat: no-repeat !important;
      }
      .curved-border-top {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: none;
      }
      .curved-border-top:hover, .activeC{
        color: #3a49f8 !important;
        font-weight: bold;
      }
      .curved-border-bottom {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: 1px solid #ddd;
        border-top: none;
      }

      .menu-drown{
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }
    }
  }
}

.topicsDesc {
  float: right;
  overflow-y: auto;
  width: 74%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: Roboto, arial, sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 30px 48px 72px;
  text-align: justify;
  #termLoad {
    color:  #404051;
    padding-top: 0px !important;
    font-size: 1em !important;
  }
  #version {
    padding-top: 0 !important;
    .btn-w{
      height: 35px;
      width: 200px;
      background-color: white;
      box-shadow: 0px 2px 4px #DBDBEA;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      margin-bottom: 40px;
    }
  }
  ul {
    padding: 0 0 2em 3em;
    li {
      list-style: circle;
      font-weight: inherit !important;
      color: #404051 !important;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
      font-size: 1rem !important;
      opacity: 0.9;
    }
  }
  div { padding-top: 80px !important; }
  .sub-paragraph{
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
    li{
      padding-left: 24px;
      text-indent: -1.5em;
      font-weight: 200;
      font-size: 0.9375em !important;
      list-style: inside;
      margin-bottom: 1em;
    }
  }
}

.loader-div {
  margin-right: 0.5rem !important;
  height: 6px !important;
  margin-bottom: 16px;
  .mat-progress-bar{
    border-radius: 5px;
    height: 6px !important;
    .mat-progress-bar-fill::after {
      background-color: #604897 !important;
    }
  }
}

.over-div{
  max-height: calc(100vh - 145px);
  overflow-y: auto;
}

@media screen and (max-width: 1030px) {
  .over-div{
    padding-bottom: 9vh;
    max-height: none;
  }
}

@media (max-width: 1000px) {
  .bubble{
    z-index: 0;
    right: 20px;
    .sub-bubble{
      width: 48px;
      height: 48px;
      img{ margin-top: 10px; }
    }
  }

  .topics{
    position: relative !important;
    width: 100% !important;
    margin-bottom: 50px;
    section{
      display: inline-block !important;
      width: 200px !important;
    }
    .sectionCh{ width: 250px !important; margin-bottom: 16px; }
    article{
      display: flex;
      flex-wrap: wrap;
      .uL, .divR{ width: 49%; }
      .divider{ display: none; }
      .divR{
        border-left: 1px solid #e0e0e0;
        text-align: right;
        h6, a { padding-right: 0px !important; }
      }
    }
  }

  .topicsDesc{ width: 100% !important; }
}

@media screen and (max-width: 600px) {
  .topicsDesc{
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media screen and (max-width: 440px) {
  .topics{
    section{
      display: block !important;
      width: auto !important;
      margin: 0px 16px 15px !important;
      select{ margin-left: 0px; }
    }
    article{
      display: block !important;
      .uL, .divR { width: 100% !important; }
      .divR{
        border-left: none;
        text-align: left !important;
        padding-top: 16px;
        .sub-title2{ padding-left: 16px !important; }
      }
    }
    ul{
      li{
        padding-bottom: 16px;
        margin: 0px 10px;
        border-bottom: 1px solid #CACAD9;
        a{ padding: 16px 8px 0px !important; }
      }
    }
    .menu-drown{ li{ padding: 0px; border: none; } }
  }
}
