p,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

::placeholder {
    color: #404051 !important;
    font-weight: 600;
    opacity: 1 !important;
}

.larger {
    font-size: 46px !important;
    margin-bottom: 1rem !important;
    padding: 0;
    margin: 0;
}

.title-80 {
    color: #404051;
    font-size: 3.5rem !important;
    margin-bottom: 1rem !important;
    padding: 0;
    margin: 0;
}

.title-60 {
    color: #404051;
    font-size: 2.5rem !important;
    margin-bottom: 1rem !important;
    padding: 0;
    margin: 0;
}

.title-40 {
    color: #404051;
    font-size: 1.75rem !important;
    margin-bottom: 1rem !important;
    padding: 0;
    margin: 0;
}

.subtitle {
    font-size: 1.5rem !important;
    margin-bottom: 1rem !important;
    padding: 0;
    margin: 0;
}

.paragraph {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem !important;
    margin-bottom: 1rem !important;
    padding: 0;
    margin: 0;
    opacity: .9;
    color: #404051;
}

a {
    font-size: 1rem !important;
    text-decoration: underline;
}

.label {
    font-family: "light" !important;
    font-size: 1rem !important;
    padding: 0;
    margin: 0;
}

.small {
    font-size: 0.8rem !important;
    padding: 0;
    margin: 0;
}

.bold {
    font-weight: bold;
}

.semi-bold{
  font-weight: 600;
}

.underline-black{
  color: black;
  text-decoration: underline;
  @extend .semi-bold;
  @extend .paragraph;
}

.underline-blue{
  color: #4088F7;
  text-decoration: underline;
  @extend .semi-bold;
  @extend .paragraph;
}

.underline-yellow{
  color: #DBA107;
  text-decoration: underline;
  @extend .semi-bold;
  @extend .paragraph;
}

.green1{
  @extend .paragraph;
  @extend .semi-bold;
  color: #149470;
}

.pointer{ cursor: pointer !important; }

@media screen and (min-width: 1600px) {
    .title-80 {
        font-size: 4.5rem !important;
    }
    .title-60 {
        font-size: 3.75rem !important;
    }
    .title-40 {
        font-size: 2.5rem !important;
    }
    .subtitle {
        font-size: 1.56rem !important;
    }
    .paragraph {
        font-size: 17px !important;
    }
    .small {
        font-size: 1rem !important;
    }
}

// Tablet
@media (max-width: 992px) {
    .title-80 {
        font-size: 2.5rem !important;
    }
    .title-60 {
        font-size: 2rem !important;
    }
    .title-40 {
        font-size: 1.5rem !important;
    }
    .subtitle {
        font-size: 1.25rem !important;
    }
    .paragraph {
        font-size: 1rem !important;
    }
    .small {
        font-size: 1rem !important;
    }
}

// Mobile
@media (max-width: 480px) {
    .title-80,
    .title-60,
    .title-40,
    .subtitle {
        font-size: 1.5rem !important;
    }
    .paragraph,
    .small {
        font-size: 1.1rem !important;
    }
}


.m-t-p-1rem{
  margin-top: 1rem;
}

div.col.p-r-2{
  padding-left: 0.75rem;
  @media only screen and (min-width: 960px){
    padding-left:  1.5rem;
  }
}

.owl-dots{
  .owl-dot span{
    background: #CACAD9 !important;
    width: 16px !important;
    height: 16px !important;
  }
  .owl-dot.active span{ background: $purpleDark !important; }
}

.owl-prev, .owl-next {
  color: #404051 !important;
  font-size: 30px !important;
  margin: 0px !important;
  padding: 4px 2px !important;;
  background: transparent !important;
  display: block;
  cursor: pointer;
  border-radius: 0px;
  position: relative;
  font-weight: 600;
  float: left;
}

.owl-next{
  float: right;
}
.iconl{
  font-size: 30px !important;
}

.owl-nav.ng-star-inserted{
  width: 112%;
  margin-left: -6%;
  transform: translateY(-25vh);
  @media screen and (max-width: 600px){
    width: 102%;
    margin-left: -2%;
  }

  /* Portrait*/
  @media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
    transform: translateY(-15vh);
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {transform: translateY(-17.5vh);}


  }

.owl-carousel .owl-item img {
  height: 250px;
  width: 250px !important;
  border-radius: 50%;
  margin: 0 auto;
}

.owl-carousel .owl-item{
  text-align: center;
  p{
    margin-top: 10px;
  }
}

.carOne .owl-item{ width: auto !important; }

.click-1{
  color: #4088F7;
  cursor: pointer;
}


.purple-text2{
  color: #6772E5 !important;
}

.white-text{
  color: white !important;
}

