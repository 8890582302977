.flex{  display: flex !important; }
.h-100 { height: 100% !important; }
.h-auto{ height: auto !important;}
.w-100 { width: 100% !important; }
.w-50 { width: 50% !important; }
.w-auto{ width: auto !important;}


.overflow-y{
    overflow-y: auto;
}


.flex-row            { flex-direction: row !important; }
.flex-column         { flex-direction: column !important; }
.flex-row-reverse    { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap         { flex-wrap: wrap !important; }
.flex-nowrap       { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.flex-fill         { flex: 1 1 auto !important; }
.flex-grow-0       { flex-grow: 0 !important; }
.flex-grow-1       { flex-grow: 1 !important; }
.flex-grow-2      { flex-grow: 2 !important; }
.flex-shrink-0     { flex-shrink: 0 !important; }
.flex-shrink-1     { flex-shrink: 1 !important; }

.justify-content-start   { justify-content: flex-start !important; }
.justify-content-end     { justify-content: flex-end !important; }
.justify-content-center  { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around  { justify-content: space-around !important; }

.align-items-start    { align-items: flex-start !important; }
.align-items-end      { align-items: flex-end !important; }
.align-items-center   { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch  { align-items: stretch !important; }

.align-content-start   { align-content: flex-start !important; }
.align-content-end     { align-content: flex-end !important; }
.align-content-center  { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around  { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }

.align-self-auto     { align-self: auto !important; }
.align-self-start    { align-self: flex-start !important; }
.align-self-end      { align-self: flex-end !important; }
.align-self-center   { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch  { align-self: stretch !important; }


.p-relative{
    position: relative !important;
}

.p-absolute{
    position: absolute !important;
}

.top-0{
    top: 0 !important;
}

.top-50-p{
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.h-36{
    height: 36px !important;
}

.h-42{
    height: 42px !important;
}

.h-135{ height: 135px !important; }

.mh-36{
    --min-height: 36px !important;
}

.mw-100{
    max-width: 100px !important;
}

.w-36{
    width: 36px !important;
}

.w-66{
  width: 66.67% !important
}

.w-33{
    width: 33.33% !important;
}

.r-16{
    right: 16px !important;
}

.r-4{
    right: 4px !important;
}

.bt-24{
    bottom: 24px !important;
}

.mw-80{
    max-width: 80% !important;
}

.d-none{
    display: none;
}

.p-fixed{
    position: fixed !important;
}


.translate-y-3px{
    transform: translateY(3px) !important;
}

.flex-for-inline{
    .mat-form-field-infix{
        display: flex !important;
    }
}
