input.passer-input {
    border: 1px solid #DBDBEA !important;
    border-radius: 5px !important;
    font-size: .9rem !important;
    padding-left: $vertical-margin !important;
    padding-right: $vertical-margin !important;
    text-align: center !important;
    height: 36px !important;
    background: white !important;
    box-shadow: none !important;
    margin-bottom: 0px;
}

input {
    box-shadow: none !important;
}

.phone-number {
    width: -moz-max-content;
    margin-top: 1rem;
    display: table;
    .wrapper {
        border: solid 1px #DBDBEA;
        background: white;
        border-radius: 5px;
        float: left;
    }
    img, .default-flag {
        width: 40px;
        float: left;
        cursor: pointer;
        border-right: 1px solid #DBDBEA;
        margin: 7px 12px;
        padding-right: 12px;
        height: auto;
    }
    input,
    input:focus {
        width: 16vw !important;
        height: 3rem !important;
        float: left;
        border-bottom: none !important;
        border-color: white !important;
        font-size: .9rem !important;
        box-shadow: none !important;
        text-align: center;
        margin: 0 !important;
        box-shadow: none !important;
    }
    button {
        margin-left: 1rem !important;
        padding: 1rem;
        background-image: linear-gradient(#656fe1, #363b79);
        border: none;
        border-radius: 5px;
        font-size: .9rem !important;
        color: white;
        font-weight: bold;
        cursor: pointer;
    }
}

input::placeholder,
input:-ms-input-placeholder,
input::-ms-input-placeholder {
    color: #DBDBEA !important;
}

.passer-input.dark::placeholder,
.passer-input.dark:-ms-input-placeholder,
.passer-input.dark::-ms-input-placeholder {
    color: #404051 !important;
}

.search{
    box-sizing: inherit !important;
    text-align: left !important;
    color: gray !important;
    background-image: url("/assets/img/lupe.png") !important;
    background-size: 20px !important;
    background-position: 95% 10px !important;
    background-repeat: no-repeat !important;
}
.search::placeholder{
    color: gray !important;
    font-weight: normal;
}

// Tablet
@media (min-width: 1600px) {
    .passer-input {
        font-size: 1.25rem;
    }
    .phone-number {
        input,
        input:focus {
            font-size: 1.25rem;
        }
        button {
            font-size: 1.25rem;
        }
    }
}

.ng-invalid:not(form) {
  border-color:#F44336;
}

.ng-invalid.required{
  border-color: #F44336;
}


input[type=email]:not(.browser-default).passer-input{
  border: solid 1px #DBDBEA;
}

input.invalid[type=email]:not(.browser-default).passer-input{
border: 1px solid #F44336;
}

input.valid[type=email]:not(.browser-default).passer-input{
  border: solid 1px #DBDBEA;
background: white !important;
background-color: white !important;
}

input[type=email]:not(.browser-default):focus:not([readonly]).passer-input{
  border: solid 1px #DBDBEA;
  margin-bottom: 0px;
}

input[type=email].passer-input.ng-invalid.ng-dirty.ng-touched{
  border: 1px solid #F44336;
}

input.passer-input.ng-dirty.ng-touched.ng-valid{
    border: solid 1px #DBDBEA;
}

input[type=text]:not(.browser-default).passer-input{
  border: solid 1px #DBDBEA;
}

input[type=text]:not(.browser-default):focus:not([readonly]).passer-input{
  border: solid 1px #DBDBEA;
}

input[type=text].passer-input.ng-touched.ng-dirty.ng-valid{
    border: solid 1px #DBDBEA;
}

.input-field.col, .input-field.wrapper{
  margin: 0px;
}

input.valid[type=text]:not(.browser-default).passer-input{
  margin-bottom: 0px;
}

.validate.passer-input.ng-dirty.ng-touched.ng-invalid{
  border: 1px solid #F44336;
}

.passer-input.ng-pristine.ng-invalid.ng-touched{
  border: 1px solid #F44336;
}

input[type=text]:not(.browser-default).passer-input + .flag-container {
  border: solid 1px #DBDBEA;
}

.passer-input.corners-left.ng-invalid.ng-touched.ng-dirty + .flag-container {
  border: 1px solid #F44336;
}

.passer-input.ng-touched.ng-dirty.ng-valid{
    border: solid 1px #DBDBEA;
}

.passer-input.dark.corners-left.ng-invalid.ng-dirty.ng-touched{
  border: 1px solid #F44336;
}

/*TEXT AREA */

.textA.ng-dirty.ng-invalid.ng-touched{
  border: 1px solid #F44336;
}

.textA.ng-untouched.ng-pristine.ng-invalid{
  border: solid 1px #DBDBEA;
}

.autoSug{
  position: relative;
  .form-control{ padding-left: 28px; }
  .fa-times-circle, .fa-search{ position: absolute; }
  .fa-search{
    top: 8px;
    left: 9px;
    color: #ccc;
  }
  .fa-times-circle{
    cursor: pointer;
    right: 10px;
    top: 15px;
    font-size: 0.875em;
  }
}
.autocomplete-list{
  position: absolute;
  width: 100%;
  .menu{
    width: 100%;
    padding: 12px 0;
    box-shadow: 0 9px 20px rgba(0,0,0,.25);
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    z-index: 11;
    max-height: 120px;
    overflow: auto;
    .menu-list{
      .menu-list-item{ padding: 6px 20px; cursor: pointer; }
      .menu-list-item:hover { background: #f2f4f6; }
    }
  }
}


//new input

.mat-form-field-infix{
  padding: 0px !important;
}

.input.mat-input-element{
  margin: 0px !important;
}

.mat-form-field{
  &.ng-valid{
    border: none !important;
  }
}

.mat-form-field-wrapper{
  padding: 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex{
  padding: 0px 8px;
}

.mat-focus-indicator{
  &:focus{
    background-color: transparent !important;
  }
}

.mat-standard-chip{
  min-height: 24px !important;
}

.mat-chip-list-wrapper{
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.chip-row{
  .mat-chip-list-wrapper{
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start;
    flex-direction: row !important;
    flex-wrap: wrap;
  }
  .chips-close{
    position: absolute;
    right: -8px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: #609ED6 !important;
    border: none !important;
}
}

.normal-datepicker .mat-form-field-infix,
.datepicker .mat-form-field-infix,
.calendar-range .mat-form-field-flex{
  display: inline-flex !important;
  align-items: center !important;
}

.datepicker {
  .mat-form-field-flex {
    opacity: 0.67;
  }
  input {
    height: 36px !important;
  }
}

.normal-datepicker {
  input {
    height: 45px !important;
  }
}

.datepicker,
.normal-datepicker {

  .mat-form-field-wrapper {
    margin: 0 !important;

    .mat-form-field-infix {
      border: 0 !important;
      
      input {
        border: none !important;
        margin: 0 !important;
        font-size: 0.9rem !important;
        text-align: center !important;
      }
    }
  }

  .mat-form-field-outline {
    background: white;
    border-radius: 5px;
  }
}