.darkgray{
  color: #404051;
}
.text17{
  font-size: 17px !important;
}
.text16{
  font-size: 16px !important;
}
.text15{
  font-size: 15px !important;
}
.text14{
  font-size: 14px !important;
}
.text13{
  font-size: 13px !important;
}
.text12{
  font-size: 12px !important;
}
.text11{
  font-size: 11px!important;
}
.text-25{
  font-size: 25px!important;
}
.text-24{
  font-size: 24px!important;
}
.text-22{
  font-size: 22px!important;
}
.text-20{
  font-size: 20px!important;
}

.text-18{
  font-size: 18px !important;
}

.text-22{
  font-size: 22px!important;
}

.text-26{
  font-size: 26px!important;
}
.text-28{
  font-size: 28px!important;
}

.lowercase{
  text-transform: lowercase !important;
}

.uppercase{
  text-transform: uppercase;
}
.capitalize{
  text-transform: capitalize !important;
}
.textAlignLeft{
  text-align: left;
}

.f_normal{
  font-weight: 400 !important;
}

.bold500{
  font-weight: 500 !important;
}
.bold-text{
  font-weight: bold;
}
.blueText{
  color: #007FFF;
}
.textAlignCenter{
  text-align: center;
}

.text-center{
  text-align: center;
}

.text-start{
  text-align: start;
}

.text-end{
  text-align: end;
}

.text-underline{
  text-decoration: underline !important;
}


.color-purple{
  color: var(--ion-color-purple) !important;
}

.color-label, .mat-form-field-label{
  color: var(--color-dark) !important;
}


.lh-12-p{
  line-height: 12px !important;
}

.code-error {
  color: red;
  font-size: smaller;
  padding-left: 0;
}

.f-italic{ font-style: italic !important; }
