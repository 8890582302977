body {
    position: static !important;
    overflow-x: hidden !important;
    background-image: linear-gradient(to right, #FFFFFF, #EFF1F7);
    display: flow-root;
}

* {
    outline: none !important;
    scroll-behavior: smooth;
    // font-family: sans-serif !important;
}

a, p, ul, li, button, span, h1, h2, h3, h4, h5, h6, input, select, b, strong{
  font-family: sans-serif !important;
}

#snackbar {
    width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: calc((100vw - 250px)/2);
    bottom: 30px;
    font-size: 1rem;
    opacity: 0;
    transition: opacity 400ms;
    z-index: 9999;
}

#snackbar.show {
    opacity: 1;
    transition: opacity 400ms;
}

.sign-in-header {
    width: 100vw;
    height: 50px;
    img {
        width: 10%;
        margin-top: 35px;
        margin-left: 14%;
    }
    button {
        float: right;
        border: solid #6772E5 1px;
        color: #6772E5;
        border-radius: 5px;
        padding: 10px 35px;
        margin-top: 30px;
        margin-right: 14%;
        background: white !important;
        box-shadow: 0 3px 6px rgba(#000000, .16);
        cursor: pointer;
    }
}

.segoe {
    font-family: 'Segoe UI' !important;
}

// Mobile
@media (max-width: 600px) {
    .header {
        .submit {
            margin-top: 16px;
        }
    }
    #snackbar {
        width: 90%;
        min-width: 90%;
        margin-left: 0;
        left: 5%;
    }
}

@import '/src/theme/scss/fontawesome.scss';
@import 'src/theme/scss/solid.scss';
@import 'src/theme/scss/brands.scss';
@import 'src/theme/scss/light.scss';
@import 'src/theme/scss/dark-theme';
@import 'src/theme/scss/regular.scss';

@import 'src/theme/abstracts/variables';
@import 'src/theme/components/passer-button';
@import 'src/theme/components/passer-modal';
@import 'src/theme/components/passer-select';
@import 'src/theme/components/passer-input';
@import 'src/theme/components/passer-check';
@import 'src/theme/components/passer-typo';
@import 'src/theme/components/spinner';
@import 'src/theme/base/typography';
@import 'src/theme/base/psr.general.grid-system';
@import 'src/theme/layout/section';

@import 'src/theme/scss/flex.scss';
@import 'src/theme/scss/spacing.scss';
@import 'src/theme/scss/borders.scss';
@import 'src/theme/scss/text.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
