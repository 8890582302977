.color-yellow{
  color: $yellow !important;
}

.color-white{
  color: white !important;
}

.color-error{
  color : $error !important;
}

.play-button{
  height: 24px;
  width: 24px;
  display: inline-block;
  padding: 0px !important;
}

.margin-right-bu{
  margin: 0rem 1rem 0rem 0px !important;
}

.helper-text, .input-field.helper-text{
  @extend .color-error;
  float: left;
  font-size: 10px !important;
  font-weight: 400;
  padding: 2px 10px 0px;
  text-align: left;
}

input.passer-input.corners-left{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.f10{ font-size: 0.625em !important; }
.f11{ font-size: 0.6875em !important; }
.f12{ font-size: 0.75em !important; }
.f13{ font-size: 0.8125em !important; }
.f14{ font-size: 0.875em !important; }
.f15{ font-size: 0.9375em !important; }
.f16{ font-size: 1em !important; }
.f17{ font-size: 1.0625em !important; }
.f18{ font-size: 1.125em !important; }
.f19{ font-size: 1.1875em !important; }
.f20{ font-size: 1.25em !important; }

.psr-text-color{ color: #404051 !important; }
