.modal-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background: rgba(#EFF1F7, 0.8);
    z-index: 100;
    margin: 0;
    .modal {
        width: 50vw;
        height: auto !important;
        margin-top: 100px !important;
        padding: 16px;
        position: fixed;
        background: white;
        border-radius: 5px;
        display: block;
        li {
            position: relative;
            display: flex;
            padding-top: 16px;
            padding-bottom: 16px;
            cursor: pointer;
            border-bottom: 1px solid #2222224d;
        }
        img {
            float: left;
            width: 32px;
            height: 32px;
        }
        li:last-child {
            border-bottom: none;
        }
        p {
            width: -moz-fit-content;
            float: left;
            margin-top: 6px;
            margin-left: 18px;
        }
        video {
            width: 100% !important;
            height: auto !important;
        }
        .cancel {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 5%;
            right: 4%;
            opacity: .5;
            cursor: pointer;
            transition: 200ms opacity;
            z-index: 99;
        }
        .set24px{
            width: 24px !important;
            height: 24px !important;
        }
        .setTopLeftCorner{
            top: 3% !important;
            right: 2% !important;
        }
        .cancel:hover {
            opacity: .7;
            transition: 200ms opacity;
        }
    }
}

@media (max-width: 460px) {
    .modal {
        width: calc(100vw - 32px) !important;
        margin: 16px !important;
        margin-top: 16px !important;
        margin-top: 80px !important;
        height: auto !important;
        video {
            width: 100% !important;
            height: auto !important;
        }
    }
}

a {
    cursor: pointer;
}
